
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's182ab2d6c997515f': `បន្ថែមវាទៅកាន់ផ្ទាំងអេក្រង់ដើមដើម្បីទទួលបាននូវបទពិសោធន៍កាន់តែល្អនិងងាយស្រួលប្រើ។`,
's1b9047d53d9f9d22': `2) ចុចលើ ប៊ូតុងចែករំលែក នៅក្នុងរបារទិសដៅ`,
's37a9e8aec5713460': `បង្ហាញបន្ថែម`,
's5c6aad7a7e4a1437': `3) ចុចលើ បន្ថែមទៅកាន់ផ្ទាំងអេក្រង់ដើម`,
's6196153c4b0c1ea0': `តំឡើង`,
's922329d6f6213590': `បន្ថែមទៅកាន់ Dock`,
'sa5ef80b4bb9b39f8': `បង្ហាញតិច`,
'sa7551383d1897fcc': `2) ចុចលើ បន្ថែមទៅកាន់ Dock`,
'sba52286c21552a4e': `តំឡើងវានៅលើឧបករណ៍របស់អ្នកដើម្បីទទួលបាននូវបទពិសោធន៍កាន់តែល្អនិងងាយស្រួលប្រើ។`,
'sc79fd7641eb9a975': `បិទការណែនាំ`,
'scdaf4bbff76674c8': `បន្ថែមទៅកាន់ផ្ទាំងអេក្រង់ដើម`,
'sdfe23506a3b0cdc8': `1) បើកនៅក្នុងកម្មវិធី Safari`,
'se740f75b95a51807': `បន្ថែមវាទៅកាន់ Dock ដើម្បីទទួលបាននូវបទពិសោធន៍កាន់តែល្អនិងងាយស្រួលប្រើ។`,
'sfea652f6580ff086': `គេហទំព័រនេះមានមុខងារជាកម្មវិធី។`,
    };
  